import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonComponent } from '../../../shared/button/button.component';

@Component({
  selector: 'app-confirmation-modal',
  standalone: true,
  imports: [TranslateModule, ButtonComponent, CommonModule],
  templateUrl: './confirmation-modal.component.html',
  styleUrl: './confirmation-modal.component.scss',
})
export class ConfirmationModalComponent {
  headingContent: string = '';
  contentName: string = '';
  imageUrl: string = '';
  isEmployee: boolean = false;
  isRoleAccess: boolean = false;
  factoryContent: string = '';
  codeContent: string = '';

  @Output() onConfirmClick: any = new EventEmitter();
  @Output() onCancelClick: any = new EventEmitter();

  constructor(
    public config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    if (this.config.data) {
      this.headingContent = this.config.data.headingContent;
      this.contentName = this.config.data.contentName;
      this.imageUrl = this.config.data.imageUrl;
      this.isEmployee = this.config.data.isEmployee;
      this.factoryContent = this.config.data.factoryContent;
      this.codeContent = this.config.data.codeContent;
    }
  }

  onConfirm = () => {
    this.ref.close('confirmed');
  };

  onCancel = () => {
    this.ref.close('cancel');
  };
}
